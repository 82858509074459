<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4">L’État a mis en place des actions pour vous aider :</h2>
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let assistance of assistances">
      <div class="shadow rounded-3 p-4 text-primary text-center">
        <h2 class="fs-3">{{assistance.name}}</h2>
        <p class="text-wrap-pretty text-start">{{assistance.content}}</p>
        <a class="btn btn-info text-white fw-bold mx-auto" href="{{assistance.website}}" *ngIf="assistance.website">Site web</a>
      </div>
    </div>
  </div>
</div>
