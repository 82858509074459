import { Component, Input, OnInit } from '@angular/core';
import { NgForOf, NgIf } from "@angular/common";
import { Answer } from "../../../models/answer.model";

@Component({
  selector: 'app-answers-details',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './answers-details.component.html',
  styleUrl: './answers-details.component.scss'
})
export class AnswersDetailsComponent implements OnInit {

  @Input() answers?: any;

  answerOpened?: Answer | null;
  currentPage: number = 1;
  itemsPerPage: number = 12;
  maxVisiblePages: number = 2;
  paginatedAnswers: any = [];

  get totalPages() {
    return Array(Math.ceil(this.answers?.length! / this.itemsPerPage)).fill(0);
  }

  ngOnInit() {
    this.updatePagination();
  }

  toggleAnswer(answer: Answer) {
    if (this.answerOpened === answer) {
      this.answerOpened = null;
    } else {
      this.answerOpened = answer;
    }
  }

  updatePagination() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedAnswers = this.answers?.slice(startIndex, endIndex) || [];
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePagination();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages.length) {
      this.currentPage++;
      this.updatePagination();
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updatePagination();
  }

  getMiddlePages(): number[] {
    const pages = [];
    const total = this.totalPages.length;

    const startPage = Math.max(2, this.currentPage - this.maxVisiblePages);
    const endPage = Math.min(total - 1, this.currentPage + this.maxVisiblePages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  shouldShowEllipsisBefore(): boolean {
    return this.currentPage > this.maxVisiblePages + 1;
  }

  shouldShowEllipsisAfter(): boolean {
    return this.currentPage < this.totalPages.length - this.maxVisiblePages;
  }
}
