import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ProImagePipe} from "../../../pipes/pro-image.pipe";

@Component({
  selector: 'app-pro-modal',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgOptimizedImage,
    ProImagePipe
  ],
  templateUrl: './pro-modal.component.html',
  styleUrl: './pro-modal.component.scss'
})
export class ProModalComponent {

  pro: any;

  constructor(private modalRef: BsModalRef) { }

  closeModal(): void {
    this.modalRef.hide();
  }
}
