import {Component, OnDestroy, OnInit} from '@angular/core';
import {SurveyService} from "../../services/survey/survey.service";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {Answer} from "../../models/answer.model";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {InfoModalComponent} from "../../components/modals/info-modal/info-modal.component";
import {AuthService} from "../../services/auth/auth.service";
import {Subscription} from "rxjs";
import {SignupModalComponent} from "../../components/modals/signup-modal/signup-modal.component";
import {AnswersDetailsComponent} from "../../components/answers/answers-details/answers-details.component";
import {
  AnswersAssociationsComponent
} from "../../components/answers/answers-associations/answers-associations.component";
import {AnswersAssistancesComponent} from "../../components/answers/answers-assistances/answers-assistances.component";
import {AnswersCompanyComponent} from "../../components/answers/answers-company/answers-company.component";

@Component({
  selector: 'app-answers',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    AnswersDetailsComponent,
    AnswersAssociationsComponent,
    AnswersAssistancesComponent,
    AnswersCompanyComponent
  ],
  templateUrl: './answers.component.html',
  styleUrl: './answers.component.scss'
})
export class AnswersComponent implements OnInit, OnDestroy {

  error: boolean = false;
  answers?: Array<any>;
  associations?: Array<any>;
  assistances?: Array<any>;
  companies?: Array<any>;
  employers?: Array<any>;
  thresholds?: any;
  thresholdOpened: any | null;
  level?: number;
  isAdo?: boolean = false;
  login?: string | null = null;
  display: string = 'ANSWERS';

  loginSubscription!: Subscription;

  modalRef!: BsModalRef;

  constructor(
    private surveySrv: SurveyService,
    private modalService: BsModalService,
    private authSrv: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setLogin();
    await this.setSurvey();

    this.loginSubscription = this.authSrv.signInEvent.subscribe(async (status: boolean) => {
      this.setLogin();
      await this.setSurvey();
    });
  }

  openModalInfo(threshold: any): void {
    const modalOptions: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        message: this.transformBr(threshold.content),
        title: threshold.questionGroupName
      }
    };
    this.modalRef = this.modalService.show(InfoModalComponent, modalOptions);
  }

  openModalSignup(): void {
    const modalOptions: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        survey: sessionStorage.getItem('survey'),
        endSignup: () => {
          this.modalRef.hide();
          this.showLogin();
        }
      }
    };
    this.modalRef = this.modalService.show(SignupModalComponent, modalOptions);
  }

  transformBr(str: string): string {
    const lines: string[] = str.split('\n');
    lines.shift();

    const response: string = lines.join('\n');

    return response.replace(/\n/g, "<br>");
  }

  getFirstLine(str: string): string {
    const lines: string[] = str.split('\n');
    if (lines) {
      return lines[0].trim();
    }
    return '';
  }

  print() {
    window.print();
  }

  async setSurvey() {
    let success = false;
    await this.surveySrv.getSurvey().subscribe(
      (data) => {
        this.answers = data.answers;
        this.level = data.level;
        this.thresholds = data.threshOlds;
        this.associations = data.associations;
        this.assistances = data.assistances;
        this.companies = data.companies;
        this.employers = data.employers;
        this.isAdo = data.isAdo;
        success = true;
      },
      (error) => {
        this.error = true;
      },
      () => {
        if (!success)
        {
          this.error = true;
        }
      }
    );
  }

  setLogin() {
    this.login = this.authSrv.getLogin();
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  showLogin(): void {
    const modalOptions: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        message: 'Il te suffit de retenir ton mot de passe et le login suivant : <span class="fw-bolder text-primary">' + this.authSrv.getLogin() + '</span>.',
        title: 'Ton compte anonyme a bien été créé !'
      }
    };
    this.modalRef = this.modalService.show(InfoModalComponent, modalOptions);
  }

  private scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  setDiplay(display: string): void {
    this.display = display;
    this.scrollToElement('details');
  }
}
