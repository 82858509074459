<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4">Autour de vous, les avocats suivantes peuvent vous accompagner :</h2>
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let pro of pros">
      <div class="shadow rounded-3 p-4 text-primary text-center">
        <h2 class="fs-3">{{pro.firstname}} {{pro.lastname}}</h2>
        <div style="height: 200px; position: relative" class="text-center">
          <img ngSrc="{{pro.image | proImage}}" class="rounded-4 img-fluid h-100 mx-auto" style="width: auto" *ngIf="pro.image" [alt]="pro.lastname" fill>
        </div>
        <h4 class="fs-6 text-info mt-3">{{ pro.city }}</h4>
        <button class="btn btn-info text-white fw-bolder mt-2 mx-auto" (click)="openModalPro(pro)">Plus d'informations</button>
      </div>
    </div>
  </div>
</div>
