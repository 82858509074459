import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'proImage',
  standalone: true
})
export class ProImagePipe implements PipeTransform {

  transform(filename: string): unknown {
    return `${environment.api.endpoint}assets/pro/image/${filename}`;
  }

}
