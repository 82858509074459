<div class="modal-header d-flex align-items-center justify-content-between">
  <h4 class="modal-title text-primary">{{ pro.firstname }} {{ pro.lastname }}</h4>
  <button type="button" class="btn btn-primary rounded-circle" aria-label="Close" (click)="closeModal()">
    <span class="material-icons-outlined text-white fw-bold m-auto">close</span>
  </button>
</div>
<div class="modal-body text-center">
  <div style="height: 200px; position: relative" class="text-center" *ngIf="pro.image" >
    <img ngSrc="{{pro.image | proImage}}" class="rounded-4 img-fluid h-100 mx-auto" style="width: auto" [alt]="pro.lastname" fill>
  </div>
  <p class="mt-3">
    {{ pro.address }} <br>
    {{ pro.city }} {{ pro.zipCode }}
  </p>
  <span class="badge bg-primary fs-6 mx-auto">{{ pro.email }}</span>
  <p class="mt-3">
    {{ pro.description }}
  </p>
  <ng-container *ngIf="pro.formations.length >= 1">
    <h3 class="text-primary">Les formations</h3>
    <ul class="mt-3 list-unstyled">
      <ng-container *ngFor="let formation of pro.formations">
      <li>{{ formation.title }}</li>
      <hr class="w-25 text-primary mx-auto">
      </ng-container>
    </ul>
  </ng-container>
  <h3 class="text-primary">Pourquoi être engagé avec Opale.care ?</h3>
  <p>{{ pro.motivation }}</p>
</div>
<div class="modal-footer d-flex justify-content-between">
  <div class="d-flex justify-content-center align-content-center">
    <a href="{{ pro.facebook }}" class="mx-1" target="_blank" *ngIf="pro.facebook"><img src="assets/images/social/facebook.svg" alt="facebook" style="height: 50px"></a>
    <a href="{{ pro.instagram }}" class="mx-1" target="_blank" *ngIf="pro.instagram"><img src="assets/images/social/instagram.svg" alt="instagram" style="height: 50px"></a>
    <a href="{{ pro.website }}" class="mx-1" target="_blank" *ngIf="pro.website"><img src="assets/images/social/website.svg" alt="website" style="height: 50px"></a>
    <a href="{{ pro.linkedin }}" class="mx-1" target="_blank" *ngIf="pro.linkedin"><img src="assets/images/social/linkedin.svg" alt="linkedin" style="height: 50px"></a>
  </div>
  <button type="button" class="btn btn-info text-white" (click)="closeModal()">Fermer</button>
</div>
