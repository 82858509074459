import {Component, Input} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ProImagePipe} from "../../../pipes/pro-image.pipe";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {InfoModalComponent} from "../../modals/info-modal/info-modal.component";
import {ProModalComponent} from "../../modals/pro-modal/pro-modal.component";

@Component({
  selector: 'app-answers-pro',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ProImagePipe,
    NgOptimizedImage
  ],
  templateUrl: './answers-pro.component.html',
  styleUrl: './answers-pro.component.scss'
})
export class AnswersProComponent {

  @Input() pros?: Array<any>;

  modalRef!: BsModalRef;

  constructor(
    private modalService: BsModalService,
  ) {
  }

  openModalPro(pro: any): void {
    const modalOptions: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        pro: pro
      }
    };
    this.modalRef = this.modalService.show(ProModalComponent, modalOptions);
  }
}
