import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-answers-associations',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './answers-associations.component.html',
  styleUrl: './answers-associations.component.scss'
})
export class AnswersAssociationsComponent {

  @Input() associations?: Array<any>;

}
