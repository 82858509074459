<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4">Voici un résumé de tes réponses et les éléments d'analyse que nous te proposons :</h2>

  <div class="row mb-5">
    <div class="col-12 col-lg-4 mb-2" *ngFor="let answer of paginatedAnswers;">
      <div class="bg-white shadow rounded text-center px-3 py-4 answer">
        <h3 class="text-primary fs-4">{{ answer.question }}</h3>
        <p class="text-muted">Votre réponse :</p>
        <div class="d-flex justify-content-between">
          <p></p>
          <p class="fs-4">{{ answer.answer }}</p>
          <span class="material-icons text-primary fs-1 pointer-event" (click)="toggleAnswer(answer)" *ngIf="answerOpened !== answer">add_circle</span>
          <span class="material-icons text-primary fs-1 pointer-event" (click)="toggleAnswer(answer)" *ngIf="answerOpened === answer">do_not_disturb_on</span>
        </div>
        <ng-container *ngIf="answerOpened === answer">
          <ng-container *ngFor="let solution of answer.solutions">
            <p class="mt-2">{{ solution.content }}</p>
            <a href="{{ solution.link }}" target="_blank" class="btn btn-primary" *ngIf="solution.link">Voir l'article</a>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <nav aria-label="Pagination">
    <ul class="pagination-custom">
      <!-- Bouton "Précédent" -->
      <li class="page-item-custom" [class.disabled]="currentPage === 1">
        <a class="page-link-custom" (click)="prevPage()">
          <span class="material-icons">chevron_left</span>
        </a>
      </li>

      <li class="page-item-custom" [class.active]="currentPage === 1">
        <a class="page-link-custom" (click)="goToPage(1)">1</a>
      </li>

      <li class="page-item-custom" *ngIf="shouldShowEllipsisBefore()">
        <span class="page-link-custom">...</span>
      </li>

      <li class="page-item-custom" *ngFor="let page of getMiddlePages()" [class.active]="currentPage === page">
        <a class="page-link-custom" (click)="goToPage(page)">{{ page }}</a>
      </li>

      <li class="page-item-custom" *ngIf="shouldShowEllipsisAfter()">
        <span class="page-link-custom">...</span>
      </li>

      <li class="page-item-custom" [class.active]="currentPage === totalPages.length">
        <a class="page-link-custom" (click)="goToPage(totalPages.length)">{{ totalPages.length }}</a>
      </li>

      <li class="page-item-custom" [class.disabled]="currentPage === totalPages.length">
        <a class="page-link-custom" (click)="nextPage()">
          <span class="material-icons">chevron_right</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
