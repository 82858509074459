<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4 text-wrap-pretty" *ngIf="type == 'COMPANY'">Votre employeur a mis en place des aides pour vous aider à faire face à cette situation dont vous n’êtes pas responsable :</h2>
  <h2 class="text-info mb-4 text-wrap-pretty" *ngIf="type == 'COMMUNITY'">Votre ville a mis en place des aides pour vous aider à faire face à cette situation dont vous n’êtes pas responsable :</h2>
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let action of employers">
      <div class="shadow rounded-3 p-4 text-primary">
        <h2 class="fs-3 text-center" [innerHTML]="replacePlaceVariable(action.content, action.variable)"></h2>
        <p class="mt-3">{{action.referent}}</p>
        <ul class="list-unstyled mt-3">
          <li class="my-1" *ngIf="action.email"><span class="fw-bold">Email</span> : <span class="text-black">{{action.email}}</span></li>
          <li class="my-1" *ngIf="action.phone"><span class="fw-bold">Téléphone</span> : <span class="text-black">{{action.phone}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>
