import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-answers-company',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './answers-company.component.html',
  styleUrl: './answers-company.component.scss'
})
export class AnswersCompanyComponent {

  @Input() employers?: Array<any>;
  @Input() type?: string;

  replacePlaceVariable(content: string, variable: string | null): string {
    if (!content || !variable) {
      return content;
    }
    console.log(content.replace(/\[(\w+)\]/g, (match) => {
      return variable || match;
    }));
    return content.replace(/\[\]/g, variable);
  }
}
