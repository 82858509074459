<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4">Autour de vous, les associations suivantes peuvent vous accompagner :</h2>
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let association of associations">
      <div class="shadow rounded-3 p-4 text-primary">
        <h2 class="fs-3 text-center">{{association.name}}</h2>
        <p>{{association.summary}}</p>
        <ul class="list-unstyled">
          <li class="my-1" *ngIf="association.website"><span class="fw-bold">Site web</span> : <span class="text-black"> <a class="text-primary" href="{{association.website}}">{{association.website}}</a> </span></li>
          <li class="my-1" *ngIf="association.schedule"><span class="fw-bold">Horaires</span> : <span class="text-black">{{association.schedule}}</span></li>
          <li class="my-1" *ngIf="association.phone"><span class="fw-bold">Téléphone</span> : <span class="text-black">{{association.phone}}</span></li>
          <li class="my-1" *ngIf="association.address"><span class="fw-bold">Adresse</span> : <span class="text-black">{{association.address}} {{association.zipCode}} {{association.city}}</span></li>
        </ul>

      </div>
    </div>
  </div>
</div>
