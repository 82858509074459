<div class="pb-5 px-md-5">
  <h2 class="text-info mb-4">L’État a mis en place des actions pour vous aider :</h2>
  <div class="row">
    <div class="col-12 col-lg-4" *ngFor="let assistance of assistances">
      <div class="shadow rounded-3 p-4 text-primary">
        <h2 class="fs-3 text-center">{{assistance.name}}</h2>
        <p class="text-wrap-pretty">{{assistance.content}}</p>
        <ul class="list-unstyled">
          <li class="my-1"><span class="fw-bold">Site web</span> : <span class="text-black">{{assistance.website}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>
