import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from "../components/footer/footer.component";
import {NavbarComponent} from "../components/navbar/navbar.component";
import { HttpClientModule } from '@angular/common/http';
import {ProgressBarComponent} from "../components/progress/progress-bar/progress-bar.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {InfoModalComponent} from "../components/modals/info-modal/info-modal.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ToastrModule} from "ngx-toastr";
import {SignupModalComponent} from "../components/modals/signup-modal/signup-modal.component";
import {AnswersDetailsComponent} from "../components/answers/answers-details/answers-details.component";
import {AnswersAssociationsComponent} from "../components/answers/answers-associations/answers-associations.component";
import {AnswersCompanyComponent} from "../components/answers/answers-company/answers-company.component";
import {AnswersAssistancesComponent} from "../components/answers/answers-assistances/answers-assistances.component";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FooterComponent,
    NavbarComponent,
    ProgressBarComponent,
    InfoModalComponent,
    AnswersDetailsComponent,
    AnswersAssociationsComponent,
    AnswersCompanyComponent,
    AnswersAssistancesComponent
  ],
  declarations: [
    SignupModalComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ProgressBarComponent,
    InfoModalComponent,
    SignupModalComponent,
    AnswersDetailsComponent,
    AnswersAssociationsComponent,
    AnswersCompanyComponent,
    AnswersAssistancesComponent
  ]
})
export class SharedModule { }
